export default {
  headers: [
    {
      id: 'mini-header',
      logo: {
        alt: 'logo',
        src: 'https://si-homelight.s3.amazonaws.com/sites/otok/logo.png',
        url: '',
      },
      breakpoint: 1048,
      desktopMenu: {
        type: 'default', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: false,
        logo: {
          show: false,
          reverseOrder: true,
          containerClassName: '',
          imageClassName: '',
        },
        headerClassName: 'on-zverinac-mini-header',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        itemClassName: 'on-zverinac-mini-header-item',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [
          {
            id: 'header-contact-item',
            iconSet: 'fa',
            className: 'envelope',
            content: 'Contact Us',
            url: 'https://www.thecahurgroup.com/contactus',
          },
          {
            id: 'header-phone-item',
            iconSet: 'fa',
            className: 'phone',
            content: '561-401-5758',
            url: 'tel:561-401-5758',
          },
        ],
      },
      mobileMenu: {
        logo: {
          show: false,
          containerClassName: '',
          imageClassName: '',
        },
        type: 'mobile-default', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: '',
          hide: true,
          reverse: false,
        },
        headerClassName: '',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: '',
        itemClassName: '',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [],
        items: [],
      },
    },
    {
      id: 'main-header',
      logo: {
        url: 'https://www.thecahurgroup.com/',
        src: 'https://si-homelight.s3.amazonaws.com/sites/zverinac/logo.jpg',
        alt: 'image',
      },
      breakpoint: 1048,
      desktopMenu: {
        type: 'default', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: true,
        logo: {
          show: true,
          reverseOrder: false,
          containerClassName: '',
          imageClassName: 'on-zverinac-header-logo',
        },
        headerClassName: 'on-zverinac-header',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        itemClassName: 'on-zverinac-header-item',
        menuClassName: 'on-zverinac-header-menu',
        menuLabelActiveClassName: 'on-zverinac-menu-active',
        menuItemClassName: 'on-zverinac-header-folder-item',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [
          {
            id: 'header-home-item',
            content: 'HOME',
            url: 'https://www.thecahurgroup.com/',
          },
          {
            id: 'header-search-item',
            content: 'SEARCH',
            menuItems: [
              {
                id: 'header-search-item-agents',
                content: 'Agents',
                url: 'https://www.thecahurgroup.com/agents/',
              },
              {
                id: 'header-search-item-map',
                content: 'Map Search',
                url: 'https://www.thecahurgroup.com/results-map/',
              },
              {
                id: 'header-search-item-listings',
                content: 'Our Listings',
                url: 'https://www.thecahurgroup.com/results-gallery/?userID=all',
              },
            ],
          },
          {
            id: 'header-buying-item',
            content: 'BUYING',
            menuItems: [
              {
                id: 'header-buying-item-buy',
                content: 'Buy',
                url: 'https://www.thecahurgroup.com/buy/',
              },
              {
                id: 'header-buying-item-cash-offer',
                content: 'Cash Offer',
                url: 'https://cashoffer.thecahurgroup.com',
              },
            ],
          },
          {
            id: 'header-financing-item',
            content: 'FINANCING',
            url: 'https://www.thecahurgroup.com/finance/',
          },
          {
            id: 'header-selling-item',
            content: 'SELLING',
            menuItems: [
              {
                id: 'header-selling-item-home',
                content: 'Sell',
                url: 'https://www.thecahurgroup.com/sell/',
              },
              {
                id: 'header-selling-item-trade-in',
                content: 'Trade In',
                url: 'https://trade-in.thecahurgroup.com',
              },
            ],
          },
          {
            id: 'header-item-home-worth',
            content: 'WHAT’S MY HOME WORTH?',
            url: 'https://www.thecahurgroup.com/home-valuation/',
          },
          {
            id: 'header-item-more',
            content: 'MORE',
            menuItems: [
              {
                id: 'header-resources-item',
                content: 'Resources',
                url: 'https://www.thecahurgroup.com/#',
              },
              {
                id: 'header-resources-item-additional',
                content: 'Additional Resources',
                url: 'https://www.thecahurgroup.com/resources/',
              },
              {
                id: 'header-resources-item-blog',
                content: 'Blog',
                url: 'https://www.thecahurgroup.com/blog/',
              },
              {
                id: 'header-resources-item-guides',
                content: 'Community Guides',
                url: 'https://www.thecahurgroup.com/guides/',
              },
              {
                id: 'header-main-contact-item',
                content: 'Contact Us',
                url: 'https://www.thecahurgroup.com/contactus/',
              },
            ],
          },
        ],
      },
      mobileMenu: {
        logo: {
          show: true,
          containerClassName: '',
          imageClassName: 'on-zverinac-header-mobile-logo',
        },
        type: 'mobile-default', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: '',
          hide: false,
          reverse: false,
        },
        headerClassName: 'on-zverinac-header-mobile',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: 'on-zverinac-header-mobile-menu',
        itemClassName: 'on-zverinac-header-mobile-item',
        menuClassName: 'on-zverinac-header-mobile-folder',
        menuLabelActiveClassName: '',
        menuItemClassName: 'on-zverinac-header-mobile-folder-item',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [],
        items: [
          {
            id: 'header-mobile-home-item',
            content: 'Home',
            url: 'https://www.thecahurgroup.com/',
          },
          {
            id: 'header-mobile-search-item',
            content: 'Search',
            menuItems: [
              {
                id: 'header-mobile-search-item-agents',
                content: 'Agents',
                url: 'https://www.thecahurgroup.com/agents/',
              },
              {
                id: 'header-mobile-search-item-map',
                content: 'Map Search',
                url: 'https://www.thecahurgroup.com/results-map/',
              },
              {
                id: 'header-mobile-search-item-listings',
                content: 'Our Listings',
                url: 'https://www.thecahurgroup.com/results-gallery/?userID=all',
              },
            ],
          },
          {
            id: 'header-mobile-buying-item',
            content: 'Buying',
            menuItems: [
              {
                id: 'header-mobile-buying-item-buy',
                content: 'Buy',
                url: 'https://www.thecahurgroup.com/buy/',
              },
              {
                id: 'header-mobile-buying-item-cash-offer',
                content: 'Cash Offer',
                url: 'https://cashoffer.thecahurgroup.com',
              },
            ],
          },
          {
            id: 'header-mobile-financing-item',
            content: 'Financing',
            url: 'https://www.thecahurgroup.com/finance/',
          },
          {
            id: 'header-mobile-selling-item',
            content: 'Selling',
            menuItems: [
              {
                id: 'header-mobile-selling-item-home',
                content: 'Sell',
                url: 'https://www.thecahurgroup.com/sell/',
              },

              {
                id: 'header-mobile-selling-item-trade-in',
                content: 'Trade In',
                url: 'https://trade-in.thecahurgroup.com',
              },
            ],
          },
          {
            id: 'header-mobile-item-home-worth',
            content: 'What’s my home worth?',
            url: 'https://www.thecahurgroup.com/home-valuation/',
          },
          {
            id: 'header-mobile-item-more',
            content: 'Resources',
            menuItems: [
              {
                id: 'header-mobile-resources-item-additional',
                content: 'Additional Resources',
                url: 'https://www.thecahurgroup.com/resources/',
              },
              {
                id: 'header-mobile-resources-item-blog',
                content: 'Blog',
                url: 'https://www.thecahurgroup.com/blog/',
              },
              {
                id: 'header-mobile-resources-item-guides',
                content: 'Community Guides',
                url: 'https://www.thecahurgroup.com/blog/',
              },
            ],
          },
          {
            id: 'header-mobile-main-contact-item',
            content: 'Contact Us',
            url: 'https://www.thecahurgroup.com/contactus/',
          },
        ],
      },
    },
  ],
  footers: [
    {
      id: 'on-zverinac-footer-main',
      className: 'on-zverinac-footer-section pt-2',
      sectionClassName: 'on-zverinac-footer-section-container',
      columns: [
        {
          id: 'col-one',
          className: 'on-zverinac-footer-column align-left col-lg-2 col-md-3 col-sm-24',
          style: {},
          items: [
            {
              id: 'col-one-heading',
              type: 'heading',
              elementType: 'h1',
              data: 'Guides',
            },
            {
              id: 'col-one-menu',
              type: 'menu',
              className: 'on-zverinac-footer-menu',
              itemClassName: 'on-zverinac-footer-menu-item',
              items: [
                {
                  id: 'col-one-item-one',
                  data: 'Hobe Sound',
                  url: 'https://www.thecahurgroup.com/guide/hobe-sound/',
                },
                {
                  id: 'col-one-item-two',
                  data: 'Jupiter',
                  url: 'https://www.thecahurgroup.com/guide/jupiter/',
                },
                {
                  id: 'col-one-item-three',
                  data: 'Lake Worth',
                  url: 'https://www.thecahurgroup.com/guide/lake-worth/',
                },
                {
                  id: 'col-one-item-four',
                  data: 'North Palm Beach',
                  url: 'https://www.thecahurgroup.com/guide/north-palm-beach/',
                },
                {
                  id: 'col-one-item-five',
                  data: 'Palm Beach Gardens',
                  url: 'https://www.thecahurgroup.com/guide/palm-beach-gardens/',
                },
                {
                  id: 'col-one-item-six',
                  data: 'More Guides >',
                  url: 'https://www.thecahurgroup.com/guides/',
                },
              ],
            },
          ],
        },
        {
          id: 'col-two',
          className: 'on-zverinac-footer-column align-left col-lg-2 col-md-3 col-sm-24',
          style: {},
          items: [
            {
              id: 'col-two-heading',
              type: 'heading',
              elementType: 'h1',
              data: 'Company',
            },
            {
              id: 'col-two-menu',
              type: 'menu',
              className: 'on-zverinac-footer-menu',
              style: {},
              items: [
                {
                  id: 'col-two-item-one',
                  data: 'Meet The Team',
                  url: 'https://www.thecahurgroup.com/agents/',
                },
                {
                  id: 'col-two-item-two',
                  data: 'Our Blog',
                  url: 'https://www.thecahurgroup.com/blog/',
                },
                {
                  id: 'col-two-item-three',
                  data: 'Contact Us',
                  url: 'https://www.thecahurgroup.com/contactus/',
                },
              ],
            },
          ],
        },
        {
          id: 'col-three',
          className: 'on-zverinac-footer-column align-left col-lg-2 col-md-3 col-sm-24',
          style: {},
          items: [
            {
              id: 'col-three-heading',
              type: 'heading',
              elementType: 'h1',
              className: '',
              style: {},
              data: 'Resources',
            },
            {
              id: 'col-three-menu',
              type: 'menu',
              className: 'on-zverinac-footer-menu',
              style: {},
              items: [
                {
                  id: 'col-three-item-one',
                  data: 'Buy a Home',
                  url: 'https://www.thecahurgroup.com/buy/',
                },
                {
                  id: 'col-three-item-two',
                  data: 'Sell Your Home',
                  url: 'https://www.thecahurgroup.com/sell/',
                },
                {
                  id: 'col-three-item-three',
                  data: 'Finance',
                  url: 'https://www.thecahurgroup.com/finance/',
                },
              ],
            },
          ],
        },
        {
          id: 'col-four',
          className: 'on-zverinac-footer-column align-left col-lg-2 col-md-3 col-sm-24',
          style: {},
          items: [
            {
              id: 'col-four-heading',
              type: 'heading',
              elementType: 'h1',
              data: 'Get Social',
            },
            {
              id: 'col-four-socials',
              type: 'socialIcons',
              className: 'on-zverinac-footer-socials',
              itemClassName: 'on-zverinac-footer-socials-item',
              style: {},
              items: [
                {
                  id: 'col-four-facebook',
                  className: '',
                  iconTypeClassName: 'fas',
                  iconClassName: 'rss',
                  url: 'https://www.thecahurgroup.com/feed',
                },
              ],
            },
          ],
        },
        {
          id: 'col-five',
          className: 'on-zverinac-footer-column align-left col-xl-4 col-lg-4 col-sm-24 pt-md-5 pt-lg-1 pt-xl-1',
          style: {},
          items: [
            {
              id: 'col-five-heading-one',
              type: 'heading',
              elementType: 'h1',
              data: 'About Us',
            },
            {
              id: 'col-five-heading-two',
              type: 'heading',
              elementType: 'p',
              data: "The Cahur Group is Palm Beach & Martin County's most innovative real estate team.",
            },
            {
              id: 'col-five-image',
              type: 'image',
              className: 'mb-0',
              style: { width: '125px' },
              linkUrl: 'https://www.homelight.com/agents/cibie-cahur-fl-3296317',
              url: 'https://si-homelight.s3.amazonaws.com/sites/zverinac/logo-footer-safe.png',
            },
            {
              id: 'col-five-heading-four',
              type: 'heading',
              elementType: 'p',
              className: 'mb-0',
              data: 'The Cahur Group | Keller Williams Realty',
            },
            {
              id: 'col-five-heading-five',
              type: 'heading',
              elementType: 'p',
              data: '4455 Military Trail Suite 100, Jupiter, FL 33458',
            },
            {
              id: 'col-six-phone',
              type: 'socialIcons',
              className: '',
              items: [
                {
                  id: 'phone',
                  className: '',
                  iconTypeClassName: 'fas',
                  iconClassName: 'phone',
                  url: 'tel:561-401-5758',
                  data: ' 561-401-5758',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'on-zverinac-footer-second',
      className: 'on-zverinac-footer-section',
      sectionClassName: 'on-zverinac-footer-section-container',
      columns: [
        {
          id: 'disclaimer-column',
          className: 'on-zverinac-footer-row-disclaimer align-left pt-3',
          style: {},
          items: [
            {
              id: 'footer-disclaimer',
              type: 'itemContent',
              className: 'pt-3 footer-disclaimer',
              items: [
                {
                  imageUrl: 'https://si-homelight.s3.amazonaws.com/sites/zverinac/logo-footer-beaches-mls.gif',
                  imageClassName: 'on-zverinac-disclaimer-image',
                },
                {
                  content:
                    'All listings featuring the BMLS logo are provided by BeachesMLS Inc. Copyright ©2022 BeachesMLS, Inc. ',
                },
                {
                  content:
                    " IDX information is provided exclusively for consumers' personal, non-commercial use and it may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing. This information is not verified for authenticity or accuracy and is not guaranteed. Data last updated 2022-05-20T00:18:10.243.",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'on-zverinac-footer-third',
      display: true,
      className: 'on-zverinac-footer-section-last',
      sectionClassName: 'on-zverinac-footer-section-container-last',
      columns: [
        {
          id: 'disclaimer-column',
          className: 'on-zverinac-footer-row-privacy align-left',
          style: {},
          items: [
            {
              id: 'boomtown-logo',
              type: 'image',
              className: 'footer-link-item-first ps-0',
              alt: 'logo',
              url: 'https://si-homelight.s3.amazonaws.com/sites/zverinac/logo-footer-boomtown.png',
              linkUrl:
                'https://boomtownroi.com/?utm_campaign=thecahurgroup.com&utm_medium=bt_client_site_referral&utm_source=client_sites',
              style: { width: '80px' },
            },
            {
              id: 'footer-2022',
              type: 'heading',
              className: 'footer-link-item-first',
              data: '© 2022',
              url: 'https://boomtownroi.com/?utm_campaign=thecahurgroup.com&utm_medium=bt_client_site_referral&utm_source=client_sites',
            },
            {
              id: 'footer-terms',
              type: 'heading',
              className: '',
              data: 'Terms of Use',
              url: 'https://www.thecahurgroup.com/terms',
            },
            {
              id: 'footer-privacy',
              type: 'heading',
              className: '',
              data: 'Privacy Policy',
              url: 'https://www.thecahurgroup.com/privacy/',
            },
            {
              id: 'footer-access',
              type: 'heading',
              className: '',
              data: 'Accessibility',
              url: 'https://www.thecahurgroup.com/accessibility/',
            },
            {
              id: 'footer-DMCA',
              type: 'heading',
              className: '',
              data: 'DMCA',
              url: 'https://www.thecahurgroup.com/dmca/',
            },
            {
              id: 'footer-sitemap',
              type: 'heading',
              className: '',
              data: 'Listings Sitemap',
              url: 'https://www.thecahurgroup.com/sitemap/',
            },
          ],
        },
      ],
    },
  ],
  substitute: {},
  replace: {
    'tradeIn.hero.agent.description': 'agentDescription',
    'cashOffer.hero.agent.description': 'agentDescription',
    'tradeIn.hero.agent.avatarImageSource': 'agentPhoto',
    'cashOffer.hero.agent.avatarImageSource': 'agentPhoto',
  },
  siteData: {
    agency: 'The Cahur Group',
    agentName: 'Cibie Cahur',
    agentDescription: 'Top agents in Jupiter, Florida',
    agentPhoto: 'https://si-homelight.s3.amazonaws.com/sites/zverinac/agent.jpg',
    heroBackground: 'https://si-homelight.s3.amazonaws.com/sites/zverinac/hero-bg.jpg',
    expressFee: '1.5%',
  },
};
